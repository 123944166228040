<template>
  <section class="">
    <b-row>
      <card-lease-summary
        v-for="lease in leases"
        :key="lease.id"
        md="6"
        :lease="lease"
        :listView="listView"
      />
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BImg,
  BCardText,
  BLink,
  BButton,
} from "bootstrap-vue";

// import store from "@/store";
import { sync } from "vuex-pathify";
import CardLeaseSummary from "@/views/card/app-custom/card-lease-summary.vue";

import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BImg,
    BCardText,
    BLink,
    BButton,

    // custom cards
    CardLeaseSummary,
  },
  computed: {
    user: sync("user/profile"),
    leases: sync("leases/list"),
  },
  data() {
    return {
      listView: false,
    };
  },
  directives: {
    Ripple,
  },
  methods: {},
  setup() {},
  mounted() {},
  created() {},
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
