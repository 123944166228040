<template>
  <!-- card 1 -->
  <!-- card 1 -->
  <b-col md="6" lg="4">
    <b-card>
      <b-card-body>
        <b-card-title
          class="mb-1 d-flex justify-content-between align-items-center"
        >
          <b-badge :variant="getStatusVariant(lease.status)">
            {{ $t("leaseStatusText")[lease.status] }}
          </b-badge>
          <b-dropdown
            :id="'menu-actions-' + lease.id"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :text="$t('Actions')"
            sm
            variant="outline-primary"
          >
            <div v-for="action in leaseActions" :key="action.value">
              <b-dropdown-item
                v-if="canSeeActionMentItem(lease, action)"
                @click="execMenuAction(action)"
              >
                <feather-icon :icon="action.icon" class="mr-0" />
                {{ action.text }}
              </b-dropdown-item>
            </div>
          </b-dropdown>

          <!-- <b-button
            v-show="lease.status == 'draft' && canEdit"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            pill
            size="sm"
            @click="deleteLease()"
          >
            <feather-icon icon="Trash2Icon" class="mr-0" />
            {{ $t("Delete Lease") }}
          </b-button> -->
        </b-card-title>

        <b-card-title
          class="mb-1 d-flex justify-content-between align-items-center"
        >
          <h5>
            <b-alert variant="primary" show dismissible>
              <div class="alert-body">
                <span>
                  <feather-icon class="mr-25" icon="InfoIcon" />
                  {{ $t("upload docs 2 certify") }}
                </span>
              </div>
            </b-alert>
          </h5>
        </b-card-title>

        <b-card-title class="d-flex justify-content-start">
          <div class="">
            <!-- <div class="col-sm-2 px-1">
              
            </div> -->
            <div class="col-sm-10 px-0">
              <feather-icon icon="MapPinIcon" class="mr-1" />
              <span>{{ lease.address }}</span>
            </div>
          </div>
        </b-card-title>

        <b-card-text>
          <form-lease-summary :show_card="true" :lease="lease" />
        </b-card-text>
      </b-card-body>
      <b-card-footer class="px-0 pb-0 d-flex justify-content-between">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          @click="editLease()"
          v-show="canEdit"
        >
          <feather-icon icon="EditIcon" class="" />
          {{ $t("Edit") }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          @click="viewLease()"
          v-show="!canEdit"
        >
          <feather-icon icon="EyeIcon" class="" />
          {{ $t("View Lease") }}
        </b-button>

        <b-button
          v-if="lease.status == 'draft'"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="success"
          @click="mintNft()"
          v-show="canEdit"
        >
          <feather-icon icon="AwardIcon" class="" />
          {{ $t("Mint NFT Btn") }}
        </b-button>

        <b-button
          v-if="lease.status == 'minted'"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="viewNft()"
        >
          <feather-icon icon="EyeIcon" class="" />
          {{ $t("See NFT") }}
        </b-button>
      </b-card-footer>
    </b-card>
  </b-col>
</template>
  

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BCardFooter,
  BCardBody,
  BBadge,
  BDropdown,
  BDropdownItem,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormLeaseSummary from "@/views/forms/components/form-leases/FormLeaseSummary.vue";

import laravel from "@/libs/laravel";
import { sync } from "vuex-pathify";
import { delArrElem, findValueInArray, getErrorMessage } from "@/libs/helpers";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BCardTitle,
    BCardFooter,
    BCardBody,
    BBadge,
    BDropdown,
    BDropdownItem,
    // custom
    FormLeaseSummary,
    BAlert,
  },
  props: {
    lease: {
      type: Object,
      default: () => {},
    },
    listView: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      leaseActions: [
        {
          text: "Mint NFT",
          value: "mint",
          icon: "AwardIcon",
          can: [this.$t("leaseStatus.DRAFT")],
        },
        {
          text: "Certify Docs",
          value: "upload",
          icon: "UploadIcon",
          can: [this.$t("leaseStatus.ACTIVE"), this.$t("leaseStatus.MINTED")],
        },
        {
          text: "Audit Records",
          value: "audit",
          icon: "EyeIcon",
          can: [this.$t("leaseStatus.ACTIVE"), this.$t("leaseStatus.MINTED")],
        },
        {
          text: "Edit Draft",
          value: "edit",
          icon: "EditIcon",
          can: [this.$t("leaseStatus.DRAFT")],
        },
        {
          text: "View Lease",
          value: "viewlease",
          icon: "EyeIcon",
          can: [this.$t("leaseStatus.ACTIVE"), this.$t("leaseStatus.MINTED")],
        },
        {
          text: "View NFT",
          value: "viewnft",
          icon: "EyeIcon",
          can: [this.$t("leaseStatus.ACTIVE"), this.$t("leaseStatus.MINTED")],
        },
        {
          text: "Delete",
          value: "delete",
          icon: "Trash2Icon",
          can: [this.$t("leaseStatus.DRAFT")],
        },
      ],
    };
  },
  computed: {
    user: sync("user/profile"),
    leases: sync("leases/list"),
    canEdit() {
      if (
        this.user &&
        this.lease &&
        this.lease.user_id &&
        this.user.id == this.lease.user_id &&
        this.lease.status == this.$t("leaseStatus")["DRAFT"]
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    execMenuAction(action) {
      console.log("execMenuAction: ", action);
      switch (action.value) {
        case "viewlease":
          console.log("exec view lease");
          this.viewLease();
          break;

        case "viewnft":
          console.log("exec view lease");
          this.viewNft();
          break;

        case "delete":
          console.log("exec delete");
          this.deleteLease();
          break;

        case "edit":
          console.log("exec edit");
          this.editLease();
          break;

        case "mint":
          console.log("exec mint");
          this.mintNft();
          break;

        default:
          break;
      }
    },

    canSeeActionMentItem(lease, action) {
      // console.log(
      //   "canSeeActionMentIte: ",
      //   lease.status,
      //   action.value,
      //   action.can
      // );
      if (action.can.length == 0) {
        console.log("can see action");
        return true;
      }
      if (findValueInArray(action.can, lease.status)) {
        return true;
      } else {
        return false;
      }
    },

    mintNft() {
      console.log("edit lease: ", this.lease);
      if (this.lease && this.lease.id) {
        this.$router.push({
          name: "mint-nft-wizard",
          params: { leaseId: this.lease.id },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error mint nft",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      }
    },
    deleteLease() {
      console.log("delete lease: ", this.lease);
      this.$bvModal
        .msgBoxConfirm(this.$t("Confirm Delete Body") + this.lease.address, {
          title: this.$t("Confirm Delete Title"),
          size: "lg",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          cancelVariant: "outline-primary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((confirmed) => {
          console.log("confirm delete: ", confirmed);
          if (!confirmed) {
            return;
          }
          laravel.leases
            .destroy(this.lease.id)
            .then((resp) => {
              console.log("destroy sucess: ", resp.data);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Lease deleted!",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });

              // remove lease from leases array
              delArrElem(this.leases, resp.data, "id");
            })
            .catch((error) => {
              console.log("destroy error: ", error.message);

              let mesg = getErrorMessage(error.message);
              mesg = mesg ? this.$t(mesg) : "Fail deleting lease";
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: mesg,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            });
        });
    },
    viewLease() {
      console.log("edit lease: ", this.lease);
      if (this.lease && this.lease.id) {
        //
        this.$router.push({
          name: "show-lease-details",
          params: { leaseId: this.lease.id },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error showing lease",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      }
    },
    editLease() {
      console.log("edit lease: ", this.lease);
      if (this.lease && this.lease.id) {
        //
        // let url = "/form/edit/lease/" + this.lease.id
        this.$router.push({
          name: "edit-lease-wizard",
          params: { leaseId: this.lease.id },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error editing lease",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      }
    },
    viewNft() {
      console.log("go to nfts: ", this.lease.nfts);
      this.$router.push({
        name: "show-nft-details",
        params: { leaseId: this.lease.id, nftId: this.lease.nfts[0].id },
      });
    },
    getStatusVariant(status) {
      switch (status) {
        case "draft":
          return "warning";
          break;
        case "minted":
          return "primary";
          break;
        case "ready2mint":
          // code block
          return "secondary";
          break;
        case "active":
          return "success";
          break;
        case "complete":
          // code block
          return "dark";
          break;
        default:
          // code block
          return "info";
      }
    },
  },
  directives: {
    Ripple,
  },
};
</script>

<style>
.card-body {
  padding: 1rem;
}
.card {
  margin: 0.3rem;
}
</style>